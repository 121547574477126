import { Card, CardContent, Skeleton } from '@mui/material';

type InstallationCardProps = {
  children: React.ReactNode;
  isLoading?: boolean;
};

const InstallationCard = ({ children, isLoading }: InstallationCardProps) => {
  return (
    <Card variant="elevation" sx={{ height: '100%' }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {isLoading ? (
          <Skeleton variant="rectangular" width="100%" height={300} />
        ) : (
          children
        )}
      </CardContent>
    </Card>
  );
};

export default InstallationCard;
