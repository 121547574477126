import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { formatDuration } from 'date-fns';
import { parse } from 'iso8601-duration';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import { formatTimestamp } from '../../../../lib/formatting';
import {
  GetBatteryChargeHistoryItem,
  GetBatteryChargeHistoryResult,
} from '../../../../redux/api/montageApi/types';

const EmptyTableBody = ({ numberOfColumns }: { numberOfColumns: number }) => {
  const { t } = useTranslation('translation');

  return (
    <TableRow>
      <TableCell colSpan={numberOfColumns} sx={{ textAlign: 'center' }}>
        <Typography color="secondary.main" variant="h6">
          {t('servicePage.historyTable.noData')}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

type TableHeaderCellProps = {
  title: string;
  subtitle: string;
};

const PriceSignalTableHeaderCell = ({
  title,
  subtitle,
}: TableHeaderCellProps) => {
  return (
    <TableCell sx={{ verticalAlign: 'top' }}>
      <Typography
        color="secondary.main"
        variant="h6"
        sx={{ textAlign: 'left', whiteSpace: 'pre-line' }}
      >
        {title}
      </Typography>
      <Typography
        color="secondary.main"
        sx={{ textAlign: 'left', whiteSpace: 'pre-line' }}
      >
        {subtitle}
      </Typography>
    </TableCell>
  );
};

const PriceSignalTableRow = ({ row }: { row: GetBatteryChargeHistoryItem }) => {
  const { t } = useTranslation('translation');

  const {
    timestamp,
    batteryId,
    chargingMode,
    relativeChargePower,
    soc,
    electricityPrice,
    prevOfflineSince,
    prevOfflineFor,
    hemsSettingChangeAt,
    hemsSettingBatteryChargingMode,
    hemsSettingBatteryChargingPriceLimit,
    hemsSettingBatteryChargingThreshold,
  } = row;
  const hemsSettingRowText = !hemsSettingChangeAt
    ? t('servicePage.noHemsSettingsYet')
    : `${hemsSettingBatteryChargingThreshold}%, ${hemsSettingBatteryChargingPriceLimit}cent, ${hemsSettingBatteryChargingMode} @ ${formatTimestamp(
        hemsSettingChangeAt,
      )}`;

  return (
    <TableRow key={`${timestamp},${batteryId}`}>
      <TableCell>{formatTimestamp(timestamp)}</TableCell>
      <TableCell>{batteryId}</TableCell>
      <TableCell>
        {`${chargingMode}${
          relativeChargePower ? `: ${relativeChargePower}%` : ''
        }`}
      </TableCell>
      <TableCell>{`${soc}%, ${electricityPrice}cent`}</TableCell>
      <TableCell>{hemsSettingRowText}</TableCell>
      <TableCell>
        {`${prevOfflineSince ? formatTimestamp(prevOfflineSince) : ''} ${
          prevOfflineFor
            ? `(${formatDuration(parse(prevOfflineFor), {
                format: ['years', 'months', 'days', 'hours', 'minutes'],
              })})`
            : ''
        }`}
      </TableCell>
    </TableRow>
  );
};

type PriceSignalTableProps = {
  data?: GetBatteryChargeHistoryResult['data'];
};

const PriceSignalTable = ({ data }: PriceSignalTableProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'servicePage.historyTable.headers',
  });

  const noData = !data || isEmpty(data);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <PriceSignalTableHeaderCell
            title={t('commandTimestamp.title')}
            subtitle={t('commandTimestamp.subtitle')}
          />
          <PriceSignalTableHeaderCell
            title={t('batterySerialNumber.title')}
            subtitle={t('batterySerialNumber.subtitle')}
          />
          <PriceSignalTableHeaderCell
            title={t('command.title')}
            subtitle={t('command.subtitle')}
          />
          <PriceSignalTableHeaderCell
            title={t('conditions.title')}
            subtitle={t('conditions.subtitle')}
          />
          <PriceSignalTableHeaderCell
            title={t('hemsSettings.title')}
            subtitle={t('hemsSettings.subtitle')}
          />
          <PriceSignalTableHeaderCell
            title={t('previousOffline.title')}
            subtitle={t('previousOffline.subtitle')}
          />
        </TableRow>
      </TableHead>
      <TableBody>
        {noData && <EmptyTableBody numberOfColumns={6} />}
        {!noData &&
          data.map(row => (
            <PriceSignalTableRow
              key={`${row.timestamp}${row.batteryId}`}
              row={row}
            />
          ))}
      </TableBody>
    </Table>
  );
};

export default PriceSignalTable;
