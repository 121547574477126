import { Grid } from '@mui/material';
import InstallationCard from '../InstallationCard';
import PriceSignalSection from '../PriceSignalSection';

type InstallationPriceSignalTabProps = {
  installationId?: string;
};

const InstallationPriceSignalTab = ({
  installationId,
}: InstallationPriceSignalTabProps) => {
  return (
    <Grid container spacing={3} mt={1}>
      <Grid item xs={12}>
        <InstallationCard>
          <PriceSignalSection installationId={installationId} />
        </InstallationCard>
      </Grid>
    </Grid>
  );
};

export default InstallationPriceSignalTab;
