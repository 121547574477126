import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Link,
} from 'react-router-dom';

import { toUpper } from 'lodash';
import './App.css';
import LMLoginPage from './components/LMLoginPage';
import ServicePortal from './components/servicePortal';
import { ApiContextProvider } from './context/ApiContext';
import { SnackbarProvider } from './context/SnackbarContext';
import TranslationContextProvider from './context/TranslationContext';
import store from './redux';
import CustomThemeProvider from './theme';

const componentMap = {
  ['ProtocolVisualDocumentationGallery'.toLowerCase()]: () =>
    import('./components/ProtocolVisualDocumentationGallery'),
  ['ServicePage'.toLowerCase()]: () =>
    import('./components/ServicePage/ServicePage'),
};

const RouteNotFound = () => {
  return <h1>Not Found</h1>;
};

/**
 *
 * @param {{ config, componentName, isRoot, isLoggedIn }} props
 * @returns a single integrated page rendering the supplied root component with the supplied config
 */
const IntegratedPage = ({
  config,
  componentName,
  isRoot,
  isLoggedIn,
  basepath,
}) => {
  if (!isLoggedIn && false) {
    return <LMLoginPage config={config} />;
  }

  if (!componentName || !componentMap[componentName.toLowerCase()]) {
    if (isRoot) {
      return (
        <Box sx={{ display: 'flex', flex: 1, pt: 2, pl: 2 }}>
          <List component="nav">
            <ListItemButton
              component="a"
              href={`${basepath || ''}/_?component=servicePage&leadId=0`}
            >
              <ListItemIcon>
                <TroubleshootIcon />
              </ListItemIcon>
              <ListItemText primary="Service Page (Lead Id in der Adresszeile anpassbar)" />
            </ListItemButton>
            <ListItemButton component={Link} to={`/servicePortal`}>
              <ListItemIcon>
                <SpaceDashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Service Portal" />
            </ListItemButton>
          </List>
        </Box>
      );
    }
    return (
      <Box sx={{ display: 'flex', flex: 1, pt: 2, pl: 2 }}>
        <Alert severity="error">
          Unable to load integrated page. Received an invalid configuration...
        </Alert>
      </Box>
    );
  }

  const Component = React.lazy(componentMap[componentName.toLowerCase()]);
  return (
    <Container disableGutters={true} maxWidth={false}>
      <Suspense fallback={<div>Loading...</div>}>
        <Component config={config} />
      </Suspense>
    </Container>
  );
};

const App = ({ config, component }) => {
  // component and config can be pass via url. Example:
  //http://localhost:3000/?component=servicePage&leadId=7551
  //http://localhost:3000/?component=protocolVisualDocumentationGallery&leadId=7551&protocolType=AC

  const queryParams = new URLSearchParams(window.location.search);
  const queryComponent = queryParams.get('component');

  let resultConfig = config || {};
  let resultComponent = component || null;

  if (resultConfig['protocolType']) {
    resultConfig['protocolType'] = toUpper(resultConfig['protocolType']);
  }

  if (queryComponent) {
    resultComponent = queryComponent;
    resultConfig['leadId'] = queryParams.get('leadId');
    resultConfig['protocolType'] = toUpper(queryParams.get('protocolType'));
    resultConfig['includeAllAdditionalOrders'] = !!parseInt(
      queryParams.get('includeAllAdditionalOrders'),
    );
    resultConfig['additionalOrderId'] = queryParams.get('additionalOrderId');
    resultConfig['includeAllComplaints'] = !!parseInt(
      queryParams.get('includeAllComplaints'),
    );
    resultConfig['complaintId'] = queryParams.get('complaintId');
    resultConfig['onlyShowPvActivation'] = !!parseInt(
      queryParams.get('onlyShowPvActivation'),
    );
    resultConfig['zeroFeedInEnabled'] = !!parseInt(
      queryParams.get('zeroFeedInEnabled'),
    );
    resultConfig['zeroFeedInDisabled'] = !!parseInt(
      queryParams.get('zeroFeedInDisabled'),
    );
    resultConfig['uploadAllowed'] = !!parseInt(
      queryParams.get('uploadAllowed'),
    );

    resultConfig = Object.fromEntries(
      Object.entries(resultConfig).filter(
        ([_key, value]) => value !== undefined && value !== null,
      ),
    );
  }

  const basepath = window?.location?.pathname?.startsWith('/montage-frontend')
    ? '/montage-frontend'
    : undefined;
  console.log('window.location', window.location, basepath);

  // Configure nested routes with JSX
  const router = createBrowserRouter(
    createRoutesFromElements([
      <Route path="servicePortal/*" element={<ServicePortal />} />,
      <Route
        path="_"
        element={
          <IntegratedPage
            componentName={resultComponent}
            config={resultConfig}
          />
        }
      />,
      <Route
        path="/"
        element={
          <IntegratedPage
            componentName={resultComponent}
            config={resultConfig}
            basepath={basepath}
            isRoot
          />
        }
        errorElement={<RouteNotFound />}
      ></Route>,
    ]),
    {
      basename: basepath,
    },
  );

  const integratedPageRouter = createBrowserRouter(
    createRoutesFromElements([
      <Route
        path="*"
        element={
          <IntegratedPage
            componentName={resultComponent}
            config={resultConfig}
          />
        }
      />,
    ]),
  );

  console.info('Routing info', {
    component,
    resultComponent: resultComponent,
    config,
    resultConfig: resultConfig,
    usesRouter: !resultComponent,
    usesIntegratedPage: Boolean(resultComponent),
  });

  return (
    <Provider store={store}>
      <ApiContextProvider baseUrl={resultConfig.baseUrl}>
        <CustomThemeProvider>
          <TranslationContextProvider>
            <SnackbarProvider>
              <ScopedCssBaseline>
                {resultComponent ? (
                  <RouterProvider router={integratedPageRouter} />
                ) : (
                  <RouterProvider router={router} />
                )}
              </ScopedCssBaseline>
            </SnackbarProvider>
          </TranslationContextProvider>
        </CustomThemeProvider>
      </ApiContextProvider>
    </Provider>
  );
};

export default App;
