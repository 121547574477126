import isEmpty from 'lodash/isEmpty';

import { clientIdToken } from '../config';
import { MIME_TYPE_PROPERTY_MAP } from '../config/constants';
import { ProjectType } from '../config/protocols/types';
import {
  VisualDocumentation,
  VisualDocumentationCategoriesConfig,
} from '../context/ProtocolVisualDocumentationContext';

export const getExtensionForMimeType = (mimeType: string) => {
  if (!mimeType) {
    return '';
  }

  const extensionForMimeType =
    MIME_TYPE_PROPERTY_MAP[mimeType]?.extension ?? '';
  return extensionForMimeType;
};

export const getVdUrlForCardMedia = (baseUrl: string, downloadUrl: string) =>
  `${baseUrl}/${downloadUrl}?montageClientIdToken=${clientIdToken}`;

export const getEarliestDate = (dates: Date[]) => {
  return dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0];
};

export const getAvailableCategories = (
  protocolType: ProjectType,
  categories: VisualDocumentationCategoriesConfig | null,
  data: VisualDocumentation[] | null,
) =>
  Object.keys(categories || {}).filter(category =>
    data!
      .filter(vd => !vd.protocolType || vd.protocolType === protocolType)
      .some(vd => vd.category === category),
  );

export function includesTyped<T extends U, U>(
  coll: ReadonlyArray<T>,
  el: U,
): el is T {
  return coll.includes(el as T);
}

export const objectToQueryString = (
  obj?: Record<string, string | number | boolean | undefined> | void,
  startsWithQuestionMark: boolean = true,
) => {
  if (!obj || isEmpty(obj)) {
    return '';
  }

  const initialQueryString = startsWithQuestionMark ? '?' : '';

  return Object.entries(obj).reduce(
    (queryString, [key, value]) =>
      !value && value !== 0
        ? queryString
        : `${queryString}${
            queryString === initialQueryString ? '' : '&'
          }${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    initialQueryString,
  );
};
