import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import CustomSnackbar, {
  SnackbarAlertType,
} from '../components/common/CustomSnackbar';

interface SnackbarContextProps {
  showSnackbar: (message: string, type: SnackbarAlertType) => void;
  closeSnackbar: () => void;
}

const SnackbarContext = createContext<SnackbarContextProps | undefined>(
  undefined,
);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

interface SnackbarState {
  isOpen: boolean;
  message: string;
  type: SnackbarAlertType;
}

const snackbarInitialState: SnackbarState = {
  isOpen: false,
  message: '',
  type: 'error',
};

export const SnackbarProvider = ({ children }: PropsWithChildren) => {
  const [snackbarState, setSnackbarState] =
    useState<SnackbarState>(snackbarInitialState);
  const { isOpen, message, type } = snackbarState;

  const showSnackbar = useCallback(
    (message: string, type: SnackbarAlertType) => {
      setSnackbarState({ isOpen: true, message, type });
    },
    [],
  );

  const closeSnackbar = useCallback(() => {
    setSnackbarState(old => ({ ...old, isOpen: false }));
  }, []);

  return (
    <SnackbarContext.Provider value={{ showSnackbar, closeSnackbar }}>
      {children}
      <CustomSnackbar
        open={isOpen}
        onClose={closeSnackbar}
        message={message}
        type={type}
      />
    </SnackbarContext.Provider>
  );
};
