import { Stack } from '@mui/material';
import { ReactElement } from 'react';
import ButtonWithIcon, { ButtonWithIconColorVariants } from '../ButtonWithIcon';

interface DialogFooterButton {
  label: string;
  onClick: () => void;
  icon: ReactElement;
  disabled?: boolean;
  colorVariant?: ButtonWithIconColorVariants;
}

interface DialogFooterProps {
  buttonsData: DialogFooterButton[];
}

const DialogFooter = ({ buttonsData }: DialogFooterProps) => {
  return (
    <Stack
      direction="row"
      sx={{ justifyContent: 'flex-end', gap: 1.125, pt: 3.75 }}
    >
      {buttonsData.map(button => {
        const { label, onClick, colorVariant, icon, disabled } = button;
        return (
          <ButtonWithIcon
            key={label}
            disabled={disabled}
            variant="contained"
            colorVariant={colorVariant}
            iconPosition="left"
            onClick={onClick}
            icon={icon}
          >
            {label}
          </ButtonWithIcon>
        );
      })}
    </Stack>
  );
};

export default DialogFooter;
