import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';

interface CustomDialogTitleProps {
  onClose?: () => void;
  children?: ReactNode;
  icon?: ReactNode;
  divider?: boolean;
}

const CustomDialogTitle = ({
  onClose,
  icon,
  children,
  divider,
}: CustomDialogTitleProps) => (
  <DialogTitle>
    <Box display="flex" justifyContent="space-between" gap={2}>
      <Box display="flex" alignItems="center" gap={1}>
        <Box display="flex" alignItems="center" sx={{ svg: { width: 20 } }}>
          {icon}
        </Box>
        <Typography variant="h6">{children}</Typography>
      </Box>
      {onClose && (
        <IconButton sx={{ mx: -1, color: 'text.secondary' }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </Box>
    {divider && <Divider sx={{ mt: 1 }} />}
  </DialogTitle>
);

export default CustomDialogTitle;
