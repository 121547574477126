import { Box, Link, Stack, Typography } from '@mui/material';

import CopyToClipboardButton from '../../common/CopyToClipboardButton';
import { InstallationDataRowItem, InstallationDataRowProps } from './types';
import PdfDownloadButton from '../../common/PdfDownloadButton';

const LinkItem = ({ value, link, valueToCopy }: InstallationDataRowItem) => {
  if (!value) {
    return null;
  }
  const onClick = () => {
    navigator.clipboard.writeText(valueToCopy || value);
    setTimeout(() => {
      window?.open(link || value, '_blank')?.focus();
    }, 200);
  };

  return (
    <Link
      {...(valueToCopy
        ? { component: 'div', onClick }
        : { href: link || value, target: '_blank' })}
      variant="body1"
      rel="noreferrer"
      sx={{
        cursor: 'pointer',
        display: 'block',
      }}
      underline="hover"
    >
      {value}
    </Link>
  );
};

const getRowItemContent = (
  { value, feature, valueToCopy, link, fileId }: InstallationDataRowItem,
  i: number,
) => {
  const key = (value ?? '') + i;

  if (feature === 'link' && !!value) {
    return (
      <LinkItem key={key} value={value} link={link} valueToCopy={valueToCopy} />
    );
  }
  if (feature === 'file' && value && fileId) {
    return (
      <PdfDownloadButton
        color="secondary.main"
        key={key}
        filename={value}
        fileId={fileId}
      />
    );
  }
  if (feature === 'copy' && !!value) {
    return (
      <Box key={key} sx={{ display: 'flex', gap: 1 }}>
        <Typography sx={{ color: 'secondary.main' }}>{value}</Typography>
        <CopyToClipboardButton textToCopy={valueToCopy ?? value} />
      </Box>
    );
  }
  return (
    <Typography key={key} color="secondary.main">
      {value ?? ''}
    </Typography>
  );
};

const InstallationDataRow = ({ label, items }: InstallationDataRowProps) => {
  return (
    <Stack direction="row" justifyContent="space-between" gap={1}>
      <Typography sx={{ color: 'secondary.main', fontWeight: 700, flex: 1 }}>
        {label}
      </Typography>
      <Box sx={{ flex: 1 }}>
        {items.map((item, index) => getRowItemContent(item, index))}
      </Box>
    </Stack>
  );
};

export default InstallationDataRow;
