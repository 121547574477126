import { TextField, TextFieldProps } from '@mui/material';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

interface FormSelectProps<T extends FieldValues> extends UseControllerProps<T> {
  selectProps?: Omit<
    TextFieldProps,
    'value' | 'onChange' | 'onBlur' | 'children' | 'select'
  >;
  children: React.ReactNode;
}

const FormSelect = <T extends FieldValues>({
  selectProps,
  children,
  ...props
}: FormSelectProps<T>) => {
  const {
    field: { onChange, onBlur, ref, value, disabled },
    fieldState: { error },
  } = useController(props);

  return (
    <TextField
      select
      ref={ref}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={!!error}
      disabled={disabled}
      {...(selectProps ?? {})}
    >
      {children}
    </TextField>
  );
};

export default FormSelect;
