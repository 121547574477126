import { createInstance, i18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { get, lowerCase, set } from 'lodash';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { I18nextProvider } from 'react-i18next';
import { BACKEND_ROUTE } from '../../config';
import { ApiContext } from '../ApiContext';
import * as de from './de.json';
import * as en from './en.json';

const builtInTranslations = {
  de,
  en,
};

export enum TranslationNamespace {
  translation = 'translation',
  protocol = 'protocol',
}
const defaultNamespace = TranslationNamespace.translation;

const withNamespace = (
  translations: object,
  namespace: string = defaultNamespace,
) =>
  Object.entries(translations).reduce((acc, [key, value]) => {
    set(acc, `${lowerCase(key)}.${namespace}`, value);
    return acc;
  }, {} as Record<string, any>);

const instanceFactory = (
  resources: any,
  callback?: (instance: i18n) => void,
) => {
  const instance = createInstance();
  instance.use(LanguageDetector);
  instance.init(
    {
      ns: Object.values(TranslationNamespace),
      defaultNS: defaultNamespace,
      fallbackLng: 'de',
      debug: process.env.NODE_ENV === 'development',
      interpolation: {
        escapeValue: false, // not needed for react!!
      },
      resources,
    },
    () => {
      if (typeof callback === 'function') {
        callback(instance);
      }
    },
  );
  return instance;
};

const i18nInstance = instanceFactory(withNamespace(builtInTranslations));
const addTranslations = (
  translations: object,
  namespace: string = defaultNamespace,
) => {
  Object.entries(translations).forEach(([key, value]) => {
    i18nInstance.addResourceBundle(
      lowerCase(key),
      namespace,
      value,
      true,
      true,
    );
  });
};

export type TranslationMetaContextType = {
  [key in TranslationNamespace]: {
    isFetching: boolean;
    isLoaded: boolean;
  };
};

const initialTranslationMetaContext: TranslationMetaContextType = {
  [TranslationNamespace.translation]: {
    isFetching: false,
    isLoaded: false,
  },
  [TranslationNamespace.protocol]: {
    isFetching: false,
    isLoaded: false,
  },
};

export const TranslationMetaContext = createContext<TranslationMetaContextType>(
  initialTranslationMetaContext,
);

const TranslationContextProvider = ({ children }: PropsWithChildren<never>) => {
  const { api, routes } = useContext(ApiContext);
  const [metaContext, setMetaContext] = useState(initialTranslationMetaContext);

  const fetchTranslations = useCallback(
    async (namespace: TranslationNamespace) => {
      setMetaContext(old => ({
        ...old,
        [namespace]: {
          ...old[namespace],
          isFetching: true,
        },
      }));

      const endpointByNamespace = {
        [TranslationNamespace.protocol]:
          routes[BACKEND_ROUTE.PROTOCOL_TRANSLATION],
      };

      const endpoint = get(endpointByNamespace, namespace, null);
      if (!endpoint) {
        setMetaContext(old => ({
          ...old,
          [namespace]: {
            ...old[namespace],
            isLoaded: false,
            isFetching: false,
          },
        }));
      }

      const protocolTranslations = await (
        await api.GET({
          endpoint,
        })
      ).json();

      /*
      const profile = await (
        await api.GET({
          endpoint: 'api/v1/auth/user/profile'
        })
      ).json();
      */

      addTranslations(protocolTranslations);
      setMetaContext(old => ({
        ...old,
        [namespace]: {
          ...old[namespace],
          isLoaded: true,
          isFetching: false,
        },
      }));
    },
    [api, routes],
  );

  useEffect(() => {
    fetchTranslations(TranslationNamespace.protocol);
  }, [fetchTranslations]);

  return (
    <TranslationMetaContext.Provider value={metaContext}>
      <I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>
    </TranslationMetaContext.Provider>
  );
};

export default TranslationContextProvider;
