import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloudIcon } from '../../../../../assets/icons/send.svg';
import ButtonWithIcon from '../../../../common/ButtonWithIcon';
import GatewayConnectionDialog from './GatewayConnectionDialog';

interface GatewayConnectionDialogTriggerButtonProps {
  projectId: string;
  customerNumber: string | null;
  serialNumber: string | null;
  disabled?: boolean;
  gatewaySecret: string | null;
}

const GatewayConnectionDialogTriggerButton = ({
  customerNumber,
  serialNumber,
  projectId,
  gatewaySecret,
  disabled = false,
}: GatewayConnectionDialogTriggerButtonProps) => {
  const { t } = useTranslation('translation');
  const [isOpen, setIsOpen] = useState(false);

  const toggleDialogVisibility = () => {
    setIsOpen(isOpen => !isOpen);
  };

  return (
    <>
      <Box>
        <ButtonWithIcon
          disabled={disabled || !customerNumber}
          variant="contained"
          onClick={toggleDialogVisibility}
          icon={<CloudIcon fill="white" />}
        >
          {t('servicePage.gatewayConnectionDialog.triggerButtonLabel')}
        </ButtonWithIcon>
      </Box>
      {customerNumber && (
        <GatewayConnectionDialog
          projectId={projectId}
          gatewaySecret={gatewaySecret ?? ''}
          customerNumber={customerNumber}
          isOpen={isOpen}
          onClose={toggleDialogVisibility}
          serialNumber={serialNumber ?? ''}
        />
      )}
    </>
  );
};

export default GatewayConnectionDialogTriggerButton;
