import { Stack, Typography } from '@mui/material';

import InstallationDataRow from './InstallationDataRow';
import { InstallationDataRowProps } from './types';

type InstallationDataSectionProps = {
  title?: string | null;
  rows: InstallationDataRowProps[];
};

const InstallationDataSection = ({
  title,
  rows,
}: InstallationDataSectionProps) => {
  return (
    <Stack direction={'column'}>
      {title && (
        <Typography variant="h5" sx={{ color: 'secondary.main', mb: 2 }}>
          {title}
        </Typography>
      )}
      {rows.map(row => (
        <InstallationDataRow key={row.label} {...row} />
      ))}
    </Stack>
  );
};

export default InstallationDataSection;
