import { Grid } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';

import { useGetInstallationHemsSettingsQuery } from '../../../../redux/api/iotCloud';
import InstallationCard from '../InstallationCard';
import InstallationDataSection from '../InstallationDataSection';
import {
  getPriceBasedChargingRows,
  getSolarBasedCarChargingRows,
} from '../helper';
import EmergencyPowerReserveSection from '../EmergencyPowerReserveSection';
import HemsSettingsAuditTable from '../HemsSettingsAuditTable';

type InstallationHemsSettingsTabProps = {
  installationId?: string;
};

const InstallationHemsSettingsTab = ({
  installationId,
}: InstallationHemsSettingsTabProps) => {
  const { t } = useTranslation('translation');

  const { data, isLoading } = useGetInstallationHemsSettingsQuery(
    installationId ?? skipToken,
  );

  const priceBasedChargingRows = getPriceBasedChargingRows(data, t);
  const solarBasedCarChargingRows = getSolarBasedCarChargingRows(data, t);

  return (
    <Grid container spacing={3} mt={1}>
      <Grid item xs={12} md={6}>
        <InstallationCard isLoading={isLoading}>
          <InstallationDataSection
            title={t(
              'servicePortal.installationDetailsPage.priceBasedCharging',
            )}
            rows={priceBasedChargingRows}
          />
        </InstallationCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <InstallationCard isLoading={isLoading}>
          <InstallationDataSection
            title={t(
              'servicePortal.installationDetailsPage.solarBasedCarCharging',
            )}
            rows={solarBasedCarChargingRows}
          />
          <EmergencyPowerReserveSection installationId={installationId} />
        </InstallationCard>
      </Grid>
      <Grid item xs={12}>
        <HemsSettingsAuditTable installationId={installationId} />
      </Grid>
    </Grid>
  );
};

export default InstallationHemsSettingsTab;
