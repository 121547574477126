import { TableBody, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { formatTimestamp } from '../../../../lib/formatting';
import {
  GetHemsSettingsAuditResult,
  HemsBatteryChargingMode,
  HemsBatteryChargingStrategy,
} from '../../../../redux/api/iotCloud/types';

type HemsSettingsAuditTableBodyProps = {
  data: GetHemsSettingsAuditResult['data'];
};

const HemsSettingsAuditTableBody = ({
  data,
}: HemsSettingsAuditTableBodyProps) => {
  const { t } = useTranslation('translation');

  return (
    <TableBody>
      {data.map(
        (
          {
            batteryChargingEnabled,
            batteryChargingThreshold,
            batteryChargingMode,
            batteryChargingStrategy,
            batteryChargingPriceLimit,
            evChargingEnabled,
            evChargingSnoozeUntilTimestamp,
            emergencyPowerEnabled,
            emergencyPowerLimit,
            changeAt,
            changeBy,
            changeType,
          },
          index,
        ) => (
          <TableRow key={index}>
            <TableCell>
              {batteryChargingEnabled ? t('common.yes') : t('common.no')}
            </TableCell>
            <TableCell>
              {batteryChargingThreshold ? `${batteryChargingThreshold}%` : '-'}
            </TableCell>
            <TableCell>
              {!batteryChargingMode
                ? '-'
                : batteryChargingMode === HemsBatteryChargingMode.Fast
                ? t('servicePortal.installationDetailsPage.fast')
                : t('servicePortal.installationDetailsPage.gentle')}
            </TableCell>
            <TableCell>
              {!batteryChargingStrategy
                ? '-'
                : batteryChargingStrategy ===
                  HemsBatteryChargingStrategy.Dynamic
                ? t('servicePortal.installationDetailsPage.dynamic')
                : t('servicePortal.installationDetailsPage.manual')}
            </TableCell>
            <TableCell>
              {batteryChargingPriceLimit
                ? `${batteryChargingPriceLimit} cent`
                : '-'}
            </TableCell>
            <TableCell>
              {evChargingEnabled ? t('common.yes') : t('common.no')}
            </TableCell>
            <TableCell>
              {evChargingSnoozeUntilTimestamp
                ? formatTimestamp(evChargingSnoozeUntilTimestamp)
                : '-'}
            </TableCell>
            <TableCell>
              {emergencyPowerEnabled ? t('common.yes') : t('common.no')}
            </TableCell>
            <TableCell>
              {emergencyPowerLimit ? `${emergencyPowerLimit}%` : '-'}
            </TableCell>
            <TableCell>{formatTimestamp(changeAt)}</TableCell>
            <TableCell>{changeBy}</TableCell>
            <TableCell>{changeType}</TableCell>
          </TableRow>
        ),
      )}
    </TableBody>
  );
};

export default HemsSettingsAuditTableBody;
