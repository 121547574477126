import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import addDays from 'date-fns/addDays';
import min from 'date-fns/min';
import subMonths from 'date-fns/subMonths';
import { useTranslation } from 'react-i18next';

import IconWithTooltip from '../../../common/IconWithTooltip';

type PriceSignalFiltersProps = {
  fromDate: Date | null;
  toDate: Date | null;
  onFromDateChange: (date: Date | null) => void;
  onToDateChange: (date: Date | null) => void;
  maxDays: number;
  onRefresh?: () => void;
};

const PriceSignalFilters = ({
  fromDate,
  toDate,
  onFromDateChange,
  onToDateChange,
  maxDays,
  onRefresh,
}: PriceSignalFiltersProps) => {
  const { t } = useTranslation('translation');

  const threeMonthsAgoDate = subMonths(new Date(), 3);
  const toDateMaxValue = min([
    addDays(new Date(), 1),
    addDays(fromDate ?? new Date(), maxDays),
  ]);

  return (
    <Box>
      <Stack direction={'row'} sx={{ alignItems: 'center', mb: 2 }} gap={1}>
        <Typography variant="h5" color="secondary.main">
          {t('servicePage.historyTable.dateRangeLabel')}
        </Typography>
        <IconWithTooltip
          titleComponent={
            <Box>
              {t('servicePage.historyTable.dateRangeTooltip', { maxDays })}
            </Box>
          }
        />
      </Stack>
      <Stack direction="row" gap={3}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            minDate={threeMonthsAgoDate}
            disableFuture
            label={t('common.fromDateLabel')}
            onChange={onFromDateChange}
            value={fromDate}
          />
          <DatePicker
            label={t('common.toDateLabel')}
            minDate={addDays(fromDate ?? new Date(), 1)}
            maxDate={toDateMaxValue}
            onChange={onToDateChange}
            value={toDate}
          />
        </LocalizationProvider>
        <IconButton
          aria-label="refresh"
          color="inherit"
          disabled={!onRefresh}
          onClick={onRefresh}
        >
          <RefreshIcon />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default PriceSignalFilters;
