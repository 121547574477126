import { createContext } from 'react';
import config from '../config';
import createApi from '../lib/js-api/api';

const { routes, ...restApiConfig } = config.api;
const initialApiContext = baseUrl => ({
  routes,
  api: createApi({
    ...restApiConfig,
    baseUrl: baseUrl || config.api.baseUrl,
  }),
});

export const ApiContext = createContext(initialApiContext());

export const ApiContextProvider = ({ children, baseUrl }) => {
  return (
    <ApiContext.Provider value={initialApiContext(baseUrl)}>
      {children}
    </ApiContext.Provider>
  );
};

export default ApiContext;
