import { useTranslation } from 'react-i18next';
import { enUS, de } from 'date-fns/esm/locale';

export const useDateLocale = () => {
  const { i18n } = useTranslation();
  if (i18n.language.startsWith('en')) {
    return enUS;
  }
  return de;
};
