import { Grid } from '@mui/material';

import PowerNowSection from '../PowerNowSection';

type InstallationDataTabProps = {
  projectId?: string;
};

const InstallationDataTab = ({ projectId }: InstallationDataTabProps) => {
  return (
    <Grid container spacing={3} mt={1}>
      <Grid item xs={12}>
        <PowerNowSection projectId={projectId} />
      </Grid>
    </Grid>
  );
};

export default InstallationDataTab;
