import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type AuthState = {
  shouldShowLmLogin: boolean;
  accessToken: string | null;
};

const initialState: AuthState = {
  shouldShowLmLogin: false,
  accessToken: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setShouldShowLmLogin(state, action: PayloadAction<boolean>) {
      state.shouldShowLmLogin = action.payload;
    },
    setAccessToken(state, action: PayloadAction<string | null>) {
      state.accessToken = action.payload;
    },
  },
});

export const { setShouldShowLmLogin, setAccessToken } = authSlice.actions;

export default authSlice;
