import { Grid } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';

import { useGetProjectServiceDataQuery } from '../../../../redux/api/montageApi';
import InstallationActions from '../InstallationActions';
import InstallationCard from '../InstallationCard';
import InstallationDataSection from '../InstallationDataSection';
import {
  getAmpereIqDataRows,
  getCustomerDataRows,
  getEkdIdDataRows,
  getProjectDataRows,
} from '../helper';

type InstallationManagementTabProps = {
  projectId?: string;
  installationId?: string;
};

const InstallationManagementTab = ({
  projectId,
  installationId,
}: InstallationManagementTabProps) => {
  const { t } = useTranslation('translation');

  const { data: serviceData, isLoading } = useGetProjectServiceDataQuery(
    projectId ?? skipToken,
  );

  const customerDataRows = getCustomerDataRows(serviceData?.customer, t);
  const ekdIdDataRows = getEkdIdDataRows(serviceData?.customer?.ekdIdStatus, t);
  const projectDataRows = getProjectDataRows(projectId ?? '', serviceData, t);
  const ampereIqDataRows = getAmpereIqDataRows(serviceData?.installation, t);

  return (
    <Grid container spacing={3} mt={1}>
      <Grid item sm={12} md={6}>
        <InstallationCard isLoading={isLoading}>
          <InstallationDataSection
            title={t('servicePage.installationData.title')}
            rows={ampereIqDataRows}
          />
        </InstallationCard>
      </Grid>
      <Grid item sm={12} md={6}>
        <InstallationCard isLoading={isLoading}>
          <InstallationActions
            projectId={projectId}
            installationId={installationId}
            serviceData={serviceData}
          />
        </InstallationCard>
      </Grid>
      <Grid item sm={12} md={6}>
        <InstallationCard isLoading={isLoading}>
          <InstallationDataSection
            title={t('servicePage.customerData.title')}
            rows={customerDataRows}
          />
          <InstallationDataSection
            title={t('servicePage.customerAccountData.title')}
            rows={ekdIdDataRows}
          />
        </InstallationCard>
      </Grid>
      <Grid item sm={12} md={6}>
        <InstallationCard isLoading={isLoading}>
          <InstallationDataSection
            title={t('servicePage.projectData.title')}
            rows={projectDataRows}
          />
        </InstallationCard>
      </Grid>
    </Grid>
  );
};

export default InstallationManagementTab;
