import { SxProps } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/checkmark.svg';

export type SnackbarAlertType = 'success' | 'error';

interface CustomSnackbarProps {
  open: boolean;
  onClose: () => void;
  type: SnackbarAlertType;
  message: string;
}

const alertStyles: { [key in SnackbarAlertType]: SxProps } = {
  error: { color: 'error.contrastText' },
  success: { color: 'success.contrastText' },
};

const CustomSnackbar = ({
  open,
  onClose,
  type,
  message,
}: CustomSnackbarProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={4500}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={onClose}
    >
      <Alert
        onClose={onClose}
        elevation={6}
        iconMapping={{ success: <CheckmarkIcon /> }}
        variant="filled"
        severity={type}
        sx={alertStyles[type]}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
