import { Box, Tooltip } from '@mui/material';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CopyToClipboardButtonProps {
  textToCopy: string;
}

const CopyToClipboardButton = ({ textToCopy }: CopyToClipboardButtonProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const { t } = useTranslation('translation');

  const onClick = () => {
    navigator.clipboard.writeText(textToCopy);
    setIsTooltipVisible(true);
    setTimeout(() => {
      setIsTooltipVisible(false);
    }, 750);
  };

  return (
    <Tooltip
      open={isTooltipVisible}
      disableHoverListener
      title={t('common.copied')}
    >
      <Box onClick={onClick} sx={{ cursor: 'pointer', display: 'inline' }}>
        <CopyIcon />
      </Box>
    </Tooltip>
  );
};

export default CopyToClipboardButton;
