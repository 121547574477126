import { BaseQueryApi, fetchBaseQuery } from '@reduxjs/toolkit/query';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';

import { RootState } from '..';
import config from '../../config';
import { setAccessToken, setShouldShowLmLogin } from '../auth';
import isNil from 'lodash/isNil';

const montageApiBaseQuery = fetchBaseQuery({
  baseUrl: config.api.baseUrl,
  prepareHeaders: headers => {
    [
      ...Object.entries(config.api.defaultHeaders),
      ...Object.entries(config.api.typedHeaders),
    ]
      .filter(([key, value]) => isNil(headers.get(key)))
      .forEach(([key, value]) => {
        headers.set(key, value);
      });
  },
  credentials: 'include',
});

const iotCloudBaseQuery = fetchBaseQuery({
  baseUrl: config.iotCloudApi.baseUrl,
  prepareHeaders: headers => {
    headers.set(
      'Content-Type',
      headers.get('Content-Type') || 'application/json',
    );
    headers.set('Accept', headers.get('Accept') || 'application/json');
    headers.set('x-client-type', 'assemblyFrontend');
    headers.set('x-auth-type', 'montageJwt');
  },
  credentials: 'include',
});

const getArgsWithToken = (args: FetchArgs | string, token: string) => {
  if (typeof args === 'string') {
    return { url: args, headers: { Authorization: `Bearer ${token}` } };
  }
  return {
    ...args,
    headers: {
      ...(args.headers ?? {}),
      Authorization: `Bearer ${token}`,
    },
  };
};

// Used to determine if user is logged in.
export const profileQueryFn = async (
  _args: any,
  api: BaseQueryApi,
  extraOptions: any,
  _baseQuery: any,
) => {
  const state = api.getState() as RootState;
  const isLmLoginShown = state.auth.shouldShowLmLogin;

  const result = await montageApiBaseQuery(
    'api/v1/auth/user/profile',
    api,
    extraOptions,
  );
  if (result.error && result.error.status === 401) {
    if (!isLmLoginShown) {
      api.dispatch(setShouldShowLmLogin(true));
    }
    return result;
  }

  const lmSessionResult = await montageApiBaseQuery(
    {
      url: 'api/v1/auth/login/lmSession',
      method: 'POST',
    },
    api,
    extraOptions,
  );
  const newAccessToken = (lmSessionResult.data as { access_token: string })
    ?.access_token;

  api.dispatch(setAccessToken(newAccessToken ?? null));
  if (isLmLoginShown) {
    api.dispatch(setShouldShowLmLogin(false));
  }

  return result;
};

export const baseQueryWithReauthFactory =
  (
    baseQuery: BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
  ): BaseQueryFn =>
  async (args, api, extraOptions) => {
    const state = api.getState() as RootState;
    const accessToken = state.auth.accessToken;

    // Optimistic case first
    if (accessToken) {
      const result = await baseQuery(
        getArgsWithToken(args, accessToken),
        api,
        extraOptions,
      );
      if (!(result.error && result.error.status === 401)) {
        return result;
      }
    }

    // Initial query failed or no access token.
    // Try to get new token.
    const lmSessionResult = await montageApiBaseQuery(
      {
        url: 'api/v1/auth/login/lmSession',
        method: 'POST',
      },
      api,
      extraOptions,
    );
    const newAccessToken = (lmSessionResult.data as { access_token: string })
      ?.access_token;

    if (!newAccessToken) {
      api.dispatch(setShouldShowLmLogin(true));
      return {
        error: {
          status: 401,
        },
      };
    }

    // Retry original query
    api.dispatch(setAccessToken(newAccessToken));
    return await baseQuery(
      getArgsWithToken(args, newAccessToken),
      api,
      extraOptions,
    );
  };

export const montageApiBaseQueryWithReauth =
  baseQueryWithReauthFactory(montageApiBaseQuery);
export const iotCloudBaseQueryWithReauth =
  baseQueryWithReauthFactory(iotCloudBaseQuery);
