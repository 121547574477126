type IotCloudInstallationServiceData = {
  id: number;
  uuid: string;
  ownerId: number | null;
  projectId: number;
  lat: number;
  lng: number;
  kgGatewaySerialNumber: string;
  kgGatewayGuid: string;
  createdAt: string;
  createdBy: number;
  updatedAt: string;
  updatedBy: number;
  userAccount: {
    id: number;
    uuid: string;
    auth0UserId: string | null;
    kgUserId: string | null;
    kgUsername: string | null;
    kgEmail: string | null;
    kgUserRoleVersion: string | null;
    deletionRequestedAt: string | null;
    mockUserId: number | null;
    createdAt: string;
    createdBy: number;
    updatedAt: string;
    updatedBy: number;
  } | null;
  hemsSetting: {
    installationId: number;
    batteryChargingEnabled: boolean | null;
    batteryChargingThreshold: number | null;
    batteryChargingMode: string | null;
    batteryChargingPriceLimit: number | null;
    evChargingEnabled: boolean | null;
    evChargingSnoozeUntilTimestamp: string | null;
    emergencyPowerEnabled: boolean | null;
    emergencyPowerLimit: number | null;
    createdAt: string;
    createdBy: number;
    updatedAt: string;
    updatedBy: number;
  };
  kgGatewaySecret: string | null;
  kgInstallerCenterLink: string | null;
  kgSmartSetupLink: string | null;
};

type EkdIdStatusServiceData = {
  projectId: number;
  username: string;
  userId: string;
  emailVerified: boolean;
  lastLogin: string;
  createdAt: string;
  projectLinkedAt: string | null;
};

export type GetProjectServiceDataResult = {
  customer: {
    number: string | null;
    name: string | null;
    email: string | null;
    phone: string | null;
    formattedAddress: string | null;
    ekdIdStatus: EkdIdStatusServiceData | null;
  };
  formattedLocationAddress: string | null;
  construction: {
    acStartDate: string | null;
    acEndDate: string | null;
    followUp1Reasons: string[] | null;
    followUp2Reasons: string[] | null;
    followUp3Reasons: string[] | null;
    projectReport: string | null;
    deliverySlips: string[] | null;
  };
  installation: IotCloudInstallationServiceData;
  gateway: {
    lmSerialNumberInverter: string | null;
    lmSerialNumber: string | null;
    protocolSerialNumber: string | null;
    protocolGatewaySecret: string | null;
  };
};

export enum InstallationOnlineStatus {
  Unknown = 'UNKNOWN',
  Offline = 'OFFLINE',
  Online = 'ONLINE',
}

export enum InstallationConnectionStatus {
  NotConnected = 'NOT_CONNECTED',
  ProjectConnected = 'PROJECT_CONNECTED',
  Connected = 'CONNECTED',
}

export type GetInstallationStatusResult = {
  lastRequest: string | null;
  onlineStatus: InstallationOnlineStatus;
  connectionStatus: InstallationConnectionStatus;
};

export type PostConnectGatewayArgs = {
  projectId: string;
  serialNumber: string;
  gatewaySecret: string;
};

export type GatewayConnectionStatus =
  | 'success'
  | 'already_connected'
  | 'failed'
  | 'user_account_not_found'
  | 'secret_not_found';

export type PostConnectGatewayResult = {
  gatewayConnectionStatus: GatewayConnectionStatus;
};

export type GetBatteryChargeHistoryArgs = {
  installationId: string;
  from: string;
  to: string;
  limit: number;
  offset?: number;
};

type ChargingMode = 'gentle' | 'fast';

export type GetBatteryChargeHistoryItem = {
  timestamp: string;
  batteryId: string;
  chargingMode: ChargingMode | null;
  relativeChargePower: number;
  soc: number;
  electricityPrice: number;
  hemsSettingBatteryChargingThreshold: number | null;
  hemsSettingBatteryChargingMode: ChargingMode | null;
  hemsSettingBatteryChargingPriceLimit: number | null;
  hemsSettingChangeAt: string | null;
  prevOfflineSince: string;
  prevOfflineFor: string;
};

export type GetBatteryChargeHistoryResult = {
  data: GetBatteryChargeHistoryItem[];
  meta: {
    total: number;
    offset: number;
    limit: number;
    count: number;
  };
};
