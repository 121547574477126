import { DeleteForever } from '@mui/icons-material';
import { DialogContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from '../../../../../assets/icons/ex.svg';
import DialogFooter from '../../../../common/dialog/DialogFooter';

type DisconnectSmartboxDialogConfirmationStageProps = {
  onClose: () => void;
  onConfirm: () => void;
};

const DisconnectSmartboxDialogConfirmationStage = ({
  onClose,
  onConfirm,
}: DisconnectSmartboxDialogConfirmationStageProps) => {
  const { t } = useTranslation('translation');

  return (
    <DialogContent>
      <Typography variant="h6">
        {t('servicePage.disconnectSmartboxDialog.confirmationText1')}
      </Typography>
      <Typography sx={{ mt: 1 }}>
        {t('servicePage.disconnectSmartboxDialog.confirmationText2')}
      </Typography>
      <DialogFooter
        buttonsData={[
          {
            colorVariant: 'secondary',
            icon: <CloseIcon />,
            label: t('common.cancel'),
            onClick: onClose,
          },
          {
            colorVariant: 'primary',
            icon: <DeleteForever />,
            label: t('common.confirm'),
            onClick: onConfirm,
          },
        ]}
      />
    </DialogContent>
  );
};

export default DisconnectSmartboxDialogConfirmationStage;
