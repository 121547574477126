import { Alert, Box, Button, LinearProgress, Stack } from '@mui/material';
import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import subDays from 'date-fns/subDays';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetBatteryChargeHistoryQuery } from '../../../../redux/api/montageApi';
import PriceSignalFilters from './PriceSignalFilters';
import PriceSignalTable from './PriceSignalTable';

const LIMIT = 10;
const MAX_DAYS = 28;

type PriceSignalSectionProps = {
  installationId?: string;
};

const PriceSignalSection = ({ installationId }: PriceSignalSectionProps) => {
  const { t } = useTranslation('translation');

  const [offset, setOffset] = useState(0);
  const [fromDate, setFromDate] = useState(subDays(new Date(), 7));
  const [toDate, setToDate] = useState(new Date());

  const { data, isLoading, isFetching, isError, refetch } =
    useGetBatteryChargeHistoryQuery(
      {
        installationId: installationId ?? '',
        limit: LIMIT,
        offset,
        from: fromDate.toISOString(),
        to: toDate.toISOString(),
      },
      { skip: !installationId },
    );

  const handleDatesChange = (newFromDate: Date, newToDate: Date) => {
    const datesDiffInDays = differenceInDays(newToDate, newFromDate);
    if (datesDiffInDays > MAX_DAYS) {
      setFromDate(newFromDate);
      setToDate(addDays(newFromDate, MAX_DAYS));
    } else if (datesDiffInDays <= 0) {
      setFromDate(newFromDate);
      setToDate(addDays(newFromDate, 1));
    } else {
      setFromDate(newFromDate);
      setToDate(newToDate);
    }
  };
  const handleFromDateChange = (date: Date | null) => {
    if (!date) {
      return;
    }
    handleDatesChange(date, toDate);
  };
  const handleToDateChange = (date: Date | null) => {
    if (!date) {
      return;
    }
    handleDatesChange(fromDate, date);
  };

  const handleNextPage = () => {
    setOffset(prev => (prev += data?.meta?.limit ?? LIMIT));
  };

  const hasNextPage =
    !!data?.meta.count &&
    (data?.meta.limit ?? LIMIT) + offset < data?.meta.count;

  return (
    <Stack gap={1}>
      <PriceSignalFilters
        fromDate={fromDate}
        toDate={toDate}
        onFromDateChange={handleFromDateChange}
        onToDateChange={handleToDateChange}
        maxDays={MAX_DAYS}
        onRefresh={refetch}
      />
      {isError && (
        <Alert
          severity="error"
          sx={{ alignItems: 'center' }}
          action={
            <Button variant="outlined" color="error" onClick={refetch}>
              Retry
            </Button>
          }
        >
          {t('common.unknownErrorTryLater')}
        </Alert>
      )}
      <Box
        sx={{
          my: 1,
          height: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {isFetching && <LinearProgress />}
      </Box>
      {!isLoading && <PriceSignalTable data={data?.data} />}
      {hasNextPage && (
        <Button disabled={isFetching} onClick={handleNextPage}>
          {t('servicePage.historyTable.loadMoreButtonLabel')}
        </Button>
      )}
    </Stack>
  );
};

export default PriceSignalSection;
