import { Box, MenuItem, TextField } from '@mui/material';
import debounce from 'lodash/debounce';
import { ChangeEvent, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { GetInstallationsArgs } from '../../../redux/api/iotCloud/types';
import { DEFAULT_RECORDS_PER_PAGE } from '.';
import { useTranslation } from 'react-i18next';

type InstallationFiltersProps = {
  onChange: (key: keyof GetInstallationsArgs, value?: string | number) => void;
};

const InstallationFilters = ({ onChange }: InstallationFiltersProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'servicePortal.installationPage',
  });
  const [searchParams] = useSearchParams();

  const debouncedOnChange = useMemo(() => debounce(onChange, 1000), [onChange]);

  const [projectId, setProjectId] = useState<string>(
    searchParams.get('projectId') ?? '',
  );
  const [kgSerialNumber, setKgSerialNumber] = useState<string>(
    searchParams.get('partialKgGatewaySerialNumber') ?? '',
  );
  const [limit, setLimit] = useState<number>(
    searchParams.get('limit')
      ? parseInt(searchParams.get('limit')!)
      : DEFAULT_RECORDS_PER_PAGE,
  );

  const handleProjectIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setProjectId(value);
      debouncedOnChange('projectId', value);
    }
  };

  const handleKgSerialNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setKgSerialNumber(value);
    debouncedOnChange('partialKgGatewaySerialNumber', value);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    setLimit(value);
    onChange('limit', value);
  };

  return (
    <Box sx={{ display: 'flex', gap: 1.5 }}>
      <TextField
        size="small"
        label={t('projectId')}
        value={projectId}
        onChange={handleProjectIdChange}
        inputProps={{ inputMode: 'numeric' }}
      />
      <TextField
        size="small"
        label={t('kgSerialNumber')}
        value={kgSerialNumber}
        onChange={handleKgSerialNumberChange}
        inputProps={{ inputMode: 'numeric' }}
      />
      <TextField
        sx={{ width: 125 }}
        size="small"
        label={t('recordsPerPage')}
        value={limit}
        onChange={handleLimitChange}
        select
      >
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
        <MenuItem value={200}>200</MenuItem>
      </TextField>
    </Box>
  );
};

export default InstallationFilters;
