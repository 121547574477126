export type GetInstallationsArgs = {
  projectId?: number;
  partialKgGatewaySerialNumber?: string;
  limit?: number;
  offset?: number;
  withTotal?: boolean;
};

export type Installation = {
  id: string;
  uuid: string;
  ownerId: string | null;
  projectId: number | null;
  lat: number | null;
  lng: number | null;
  kgGatewaySerialNumber: string | null;
  kgGatewayGuid: string | null;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  gatewayType: string;
  azureDeviceId: number | null;
};

export type GetInstallationsResult = {
  data: Installation[];
  meta: {
    total: number;
    offset: number;
    limit: number;
    count: number;
  };
};

export enum HemsBatteryChargingMode {
  Gentle = 'gentle',
  Fast = 'fast',
}

export enum HemsBatteryChargingStrategy {
  Manual = 'manual',
  Dynamic = 'dynamic',
}

export type HemsSettings = {
  batteryChargingEnabled: boolean;
  batteryChargingThreshold: number | null;
  batteryChargingMode: HemsBatteryChargingMode | null;
  batteryChargingPriceLimit: number;
  batteryChargingStrategy: HemsBatteryChargingStrategy;
  evChargingEnabled: boolean;
  evChargingSnoozeUntilTimestamp: Date | null;
  emergencyPowerEnabled: boolean;
  emergencyPowerLimit: number | null;
};

export type PatchHemsSettingsArgs = {
  installationId: string;
  body: Partial<HemsSettings>;
};

export type HemsSettingsAudit = HemsSettings & {
  changeAt: string;
  changeBy: string;
  changeType: string;
  changeRef: string;
};

export type GetHemsSettingsAuditResult = {
  data: HemsSettingsAudit[];
  meta: {
    total: number;
    offset: number;
    limit: number;
    count: number;
  };
};

export type GetHemsSettingsAuditArgs = {
  installationId: string;
  changedAtAfter?: string;
  changedAtBefore?: string;
  offset?: number;
  limit?: number;
};

export type GetInstallationPowerNowResult = {
  batteryPower: number | null;
  batterySoc: number | null;
  gridPower: number | null;
  heatPumpPower: number | null;
  heatingRodPower: number | null;
  housePower: number | null;
  pvPower: number | null;
  wallboxPower: number | null;
};
