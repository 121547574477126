import { TextField, TextFieldProps } from '@mui/material';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

interface FormNumberFieldProps<T extends FieldValues>
  extends UseControllerProps<T> {
  textFieldProps?: Omit<TextFieldProps, 'value' | 'onChange' | 'onBlur'>;
}

const FormNumberField = <T extends FieldValues>({
  textFieldProps,
  ...props
}: FormNumberFieldProps<T>) => {
  const {
    field: { onChange, onBlur, ref, value, disabled },
    fieldState: { error },
  } = useController(props);

  return (
    <TextField
      ref={ref}
      size="small"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={!!error}
      type="number"
      helperText={!disabled && error?.message}
      disabled={disabled}
      {...(textFieldProps ?? {})}
    />
  );
};

export default FormNumberField;
