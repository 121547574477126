import { DeleteForever } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonWithIcon from '../../../../common/ButtonWithIcon';
import DisconnectSmartboxDialog from './DisconnectSmartboxDialog';

type DisconnectSmartboxDialogTriggerButtonProps = {
  installationId: string | undefined;
  disabled?: boolean;
};

const DisconnectSmartboxDialogTriggerButton = ({
  installationId,
  disabled,
}: DisconnectSmartboxDialogTriggerButtonProps) => {
  const { t } = useTranslation('translation');
  const [isOpen, setIsOpen] = useState(false);

  const toggleDialogVisibility = () => {
    setIsOpen(isOpen => !isOpen);
  };

  return (
    <>
      <Box>
        <ButtonWithIcon
          disabled={disabled}
          variant="contained"
          colorVariant="secondary"
          onClick={toggleDialogVisibility}
          icon={<DeleteForever fill="white" />}
        >
          {t('servicePage.disconnectSmartboxDialog.triggerButtonLabel')}
        </ButtonWithIcon>
      </Box>
      {installationId && (
        <DisconnectSmartboxDialog
          installationId={installationId}
          isOpen={isOpen}
          onClose={toggleDialogVisibility}
        />
      )}
    </>
  );
};

export default DisconnectSmartboxDialogTriggerButton;
