import { Edit as EditIcon } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import EmergencyPowerReserveForm from './EmergencyPowerReserveForm';
import { useState } from 'react';
import EmergencyPowerReserveData from './EmergencyPowerReserveData';

type EmergencyPowerReserveSectionProps = {
  installationId?: string;
};

const EmergencyPowerReserveSection = ({
  installationId,
}: EmergencyPowerReserveSectionProps) => {
  const { t } = useTranslation('translation');

  const [isEditMode, setIsEditMode] = useState(false);

  const toggleEditMode = () => {
    setIsEditMode(prev => !prev);
  };

  return (
    <Stack direction="column">
      <Stack direction="row" alignItems="center" gap={1} mb={2}>
        <Typography variant="h5" sx={{ color: 'secondary.main' }}>
          {t('servicePortal.installationDetailsPage.emergencyPowerReserve')}
        </Typography>
        <IconButton
          sx={{ opacity: isEditMode ? 0.5 : 1 }}
          disabled={isEditMode}
          onClick={toggleEditMode}
        >
          <EditIcon color="primary" />
        </IconButton>
      </Stack>
      {isEditMode ? (
        <EmergencyPowerReserveForm
          onClose={toggleEditMode}
          installationId={installationId}
        />
      ) : (
        <EmergencyPowerReserveData installationId={installationId} />
      )}
    </Stack>
  );
};

export default EmergencyPowerReserveSection;
