import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {
  Box,
  Link,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { ReactComponent as PdfIcon } from '../../assets/icons/pdf.svg';
import config, { clientIdToken } from '../../config';
import { useSnackbar } from '../../context/SnackbarContext';

const getDownloadUrl = (id: string) =>
  `${config.api.baseUrl}/api/v1/project/attachment/${id}?montageClientIdToken=${clientIdToken}`;

type PdfDownloadButtonProps = TypographyProps & {
  filename: string;
  fileId: string;
};

const PdfDownloadButton = ({
  filename,
  fileId,
  ...props
}: PdfDownloadButtonProps) => {
  const theme = useTheme();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation('translation');

  const downloadUrl = getDownloadUrl(fileId);

  const downloadPdf = async () => {
    const response = await fetch(downloadUrl);
    if (response.status === 200) {
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    } else {
      showSnackbar(t('common.fileFetchError'), 'error');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
      }}
    >
      <Typography
        {...props}
        onClick={downloadPdf}
        color="link.main"
        sx={{
          ml: 1,
          mt: 1,
          ':hover': {
            textDecoration: 'underline',
            textDecorationColor: theme.palette.link.main,
          },
          cursor: 'pointer',
          ...props.sx,
        }}
      >
        <CloudDownloadIcon color="primary" fontSize="small" />
      </Typography>
      <PdfIcon />
      <Link
        href={downloadUrl}
        color="link.main"
        target="_blank"
        variant="body1"
        sx={{
          display: 'block',
          textDecoration: 'none',
          ':hover': { textDecoration: 'underline' },
        }}
      >
        {filename}
      </Link>
    </Box>
  );
};

export default PdfDownloadButton;
