/* eslint-disable */
// TODO: Fix TypeScript configuration and apply necessary changes so there is no need to disable eslint here

export const ENCODING = {
  BASE64: 'base64',
};
export const ERROR = {
  NOCONNECTION: 'NOCONNECTION',
  TIMEOUT: 'TIMEOUT',
  UNPARSABLE_RESPONSE: 'UNPARSABLE_RESPONSE',
};
export const REQUESTBODYTYPE = {
  url: 'url',
  json: 'json',
  binary: 'binary',
  binaryfrombase64: 'binaryfrombase64',
};

export const APIMETHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export const apiMethodsWithoutBody = [APIMETHOD.GET, APIMETHOD.DELETE];
export const apiMethodsWithBody = [
  APIMETHOD.POST,
  APIMETHOD.PUT,
  APIMETHOD.PATCH,
];

export const defaultPromiseTimeOut = 500;
export const withTimeout =
  logger =>
  (
    ms,
    inPromise = new Promise(() => undefined),
    error = ERROR.TIMEOUT,
    ...args
  ) => {
    if (!ms && ms !== 0) {
      logger.warn(
        'Promise timeout'`Promise was used with withTimeout, but no timeout was specified. Will timeout after ${defaultPromiseTimeOut} ms.`,
      );
    }
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(
        reject,
        ms || ms === 0 ? ms : defaultPromiseTimeOut,
        error,
        ...args,
      );
    });
    return Promise.race([inPromise, timeoutPromise]);
  };
