import { Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ServicePortalLayout from '../ServicePortalLayout';
import InstallationHemsSettingsTab from './tabs/InstallationHemsSettingsTab';
import InstallationManagementTab from './tabs/InstallationManagementTab';
import InstallationOverviewTab from './tabs/InstallationOverviewTab';
import InstallationPriceSignalTab from './tabs/InstallationPriceSignalTab';
import InstallationDataTab from './tabs/InstallationDataTab';

export type InstallationDetailTabs =
  | 'overview'
  | 'management'
  | 'data'
  | 'hems'
  | 'price';

const InstallationDetailsPage = () => {
  const { t } = useTranslation('translation');
  const { projectId, installationId } = useParams();

  const [currentTab, setCurrentTab] =
    useState<InstallationDetailTabs>('overview');

  const handleTabChange = (_event: any, value: InstallationDetailTabs) => {
    setCurrentTab(value);
  };

  return (
    <ServicePortalLayout>
      <Tabs value={currentTab} onChange={handleTabChange}>
        <Tab
          label={t('servicePortal.installationDetailsPage.overview')}
          value="overview"
        />
        <Tab
          label={t('servicePortal.installationDetailsPage.management')}
          value="management"
        />
        <Tab
          label={t('servicePortal.installationDetailsPage.data')}
          value="data"
        />
        <Tab
          label={t('servicePortal.installationDetailsPage.hemsSettings')}
          value="hems"
        />
        <Tab
          label={t('servicePortal.installationDetailsPage.priceSignal')}
          value="price"
        />
      </Tabs>
      {currentTab === 'overview' && (
        <InstallationOverviewTab
          projectId={projectId}
          installationId={installationId}
          onTabChange={setCurrentTab}
        />
      )}
      {currentTab === 'management' && (
        <InstallationManagementTab
          projectId={projectId}
          installationId={installationId}
        />
      )}
      {currentTab === 'data' && <InstallationDataTab projectId={projectId} />}
      {currentTab === 'hems' && (
        <InstallationHemsSettingsTab installationId={installationId} />
      )}
      {currentTab === 'price' && (
        <InstallationPriceSignalTab installationId={installationId} />
      )}
    </ServicePortalLayout>
  );
};

export default InstallationDetailsPage;
