import { Stack } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';

import { useGetInstallationStatusQuery } from '../../../redux/api/montageApi';
import {
  GetProjectServiceDataResult,
  InstallationConnectionStatus,
} from '../../../redux/api/montageApi/types';
import DisconnectSmartboxDialogTriggerButton from './dialogs/DisconnectSmartboxDialog/DisconnectSmartboxDialogTriggerButton';
import EkdIdReminderEmailDialogTriggerButton from './dialogs/EkdIdReminderEmailDialog/EkdIdReminderEmailDialogTriggerButton';
import GatewayConnectionDialogTriggerButton from './dialogs/GatewayConnectionDialog/GatewayConnectionDialogTriggerButton';

type InstallationActionsProps = {
  projectId?: string;
  installationId?: string;
  serviceData?: GetProjectServiceDataResult;
};

const InstallationActions = ({
  projectId,
  installationId,
  serviceData,
}: InstallationActionsProps) => {
  const { data: installationStatusData } = useGetInstallationStatusQuery(
    installationId ?? skipToken,
  );

  const canConnect =
    projectId &&
    installationStatusData &&
    installationStatusData?.connectionStatus !==
      InstallationConnectionStatus.Connected;
  const canDisconnect =
    installationId &&
    installationStatusData &&
    installationStatusData?.connectionStatus !==
      InstallationConnectionStatus.NotConnected;
  const canRemindEkdId =
    projectId && serviceData && !serviceData?.customer?.ekdIdStatus?.userId;

  return (
    <Stack flex={1} gap={2}>
      <GatewayConnectionDialogTriggerButton
        disabled={!canConnect}
        projectId={projectId ?? ''}
        customerNumber={serviceData?.customer?.number ?? null}
        serialNumber={
          serviceData?.gateway?.protocolSerialNumber ??
          serviceData?.gateway?.lmSerialNumber ??
          null
        }
        gatewaySecret={null}
      />
      <EkdIdReminderEmailDialogTriggerButton
        disabled={!canRemindEkdId}
        emailAddress={serviceData?.customer?.email ?? ''}
        projectId={projectId ?? ''}
      />
      <DisconnectSmartboxDialogTriggerButton
        disabled={!canDisconnect}
        installationId={installationId}
      />
    </Stack>
  );
};

export default InstallationActions;
