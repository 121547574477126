import { Alert, Box, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getLeadsMiddlewareLoginUrl } from '../config';

type LMLoginPageProps = {
  onLoad?: () => void;
  config?: { withLmIframeLogin?: boolean };
  withIframe?: boolean;
};

export const LMLoginPage = ({
  onLoad,
  config,
  withIframe,
}: LMLoginPageProps) => {
  const withLmIframeLogin = withIframe ?? config?.withLmIframeLogin ?? true;
  const { t } = useTranslation('translation');
  const lmLoginUrl = getLeadsMiddlewareLoginUrl();

  return (
    <>
      <Box sx={{ display: 'flex', flex: 1, p: 2, position: 'absolute' }}>
        <Alert severity={'info'}>
          <Link href={lmLoginUrl}>{t('common.loginFallbackMessage')}</Link>
        </Alert>
      </Box>
      {withLmIframeLogin && (
        <iframe
          sandbox="allow-top-navigation allow-same-origin allow-scripts allow-popups allow-forms"
          title="LM Login"
          referrerPolicy="same-origin"
          style={{ width: '100%', height: 800, flex: 1 }}
          src={lmLoginUrl}
          onLoad={onLoad}
        ></iframe>
      )}
    </>
  );
};

export default LMLoginPage;
