export const BACKEND_ROUTE = {
  VISUAL_DOCUMENTATION: 'VISUAL_DOCUMENTATION',
  VISUAL_DOCUMENTATION_UPLOAD: 'VISUAL_DOCUMENTATION_UPLOAD',
  VISUAL_DOCUMENTATION_CATEGORIES: 'VISUAL_DOCUMENTATION_CATEGORIES',
  PROTOCOL_TRANSLATION: 'PROTOCOL_TRANSLATION',
  PROJECT_SERVICE_DATA: 'PROJECT_SERVICE_DATA',
  INSTALLATION_STATUS: 'INSTALLATION_STATUS',
  GATEWAY_CONNECTION: 'GATEWAY_CONNECTION',
  EKD_ID_REMINDER_EMAIL: 'EKD_ID_REMINDER_EMAIL',
  ATTACHMENT: 'ATTACHMENT',
  INSTALLATION_BATTERY_CHARGE_HISTORY: 'INSTALLATION_BATTERY_CHARGE_HISTORY',
  DELETE_INSTALLATION: 'DELETE_INSTALLATION',
  DELETE_VISUAL_DOCUMENTATION: 'DELETE_VISUAL_DOCUMENTATION',
  USER_PROFILE: 'USER_PROFILE',
  INSTALLATION: 'INSTALLATION',
};

export const MIME_TYPE = {
  IMAGE_BMP: 'image/bmp',
  IMAGE_GIF: 'image/gif',
  IMAGE_HEIC: 'image/heic',
  IMAGE_HEIF: 'image/heif',
  IMAGE_JPEG: 'image/jpeg',
  IMAGE_JPG: 'image/jpg',
  IMAGE_PNG: 'image/png',
  IMAGE_SVG_XML: 'image/svg+xml',
  VIDEO_MP4: 'video/mp4',
  VIDEO_MPEG: 'video/mpeg',
  VIDEO_MOV: 'video/quicktime',
};

export const MIME_TYPE_PROPERTY_MAP = {
  [MIME_TYPE.IMAGE_BMP]: {
    extension: '.bmp',
  },
  [MIME_TYPE.IMAGE_GIF]: {
    extension: '.gif',
  },
  [MIME_TYPE.IMAGE_HEIC]: {
    extension: '.heic',
  },
  [MIME_TYPE.IMAGE_HEIF]: {
    extension: '.heif',
  },
  [MIME_TYPE.IMAGE_JPEG]: {
    extension: '.jpeg',
  },
  [MIME_TYPE.IMAGE_JPG]: {
    extension: '.jpg',
  },
  [MIME_TYPE.IMAGE_PNG]: {
    extension: '.png',
  },
  [MIME_TYPE.IMAGE_SVG_XML]: {
    extension: '.svg',
  },
  [MIME_TYPE.VIDEO_MP4]: {
    extension: '.mp4',
  },
  [MIME_TYPE.VIDEO_MPEG]: {
    extension: '.mpeg',
  },
  [MIME_TYPE.VIDEO_MOV]: {
    extension: '.mov',
  },
};

export const FIELD_TYPES = {
  textField: 'textField',
  numberField: 'numberField',
  radioGroup: 'radioGroup',
  checkbox: 'checkbox',
  radioGroupBoolean: 'radioGroupBoolean',
  multiLineText: 'multiLineText',
  number: 'number',
  textFieldGroup: 'textFieldGroup',
  doubleTextField: 'doubleTextField',
  radioGroupModuleCount: 'radioGroupModuleCount',
  moduleDocumentation: 'moduleDocumentation',
  moduleDocumentationInfoBox: 'moduleDocumentationInfoBox',
  mppSection: 'mppSection',
  visualDocumentation: 'visualDocumentation',
  documents: 'documents',
  startProtocol: 'startProtocol',
  infoBox: 'infoBox',
  protocolFinishSection: 'protocolFinishSection',
  deliverySummarySection: 'deliverySummarySection',
} as const;

export enum BackendErrorCode {
  InstallationInUse = 'installation_in_use',
}
