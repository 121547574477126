import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import config from '../../../config';
import { formatTimestamp } from '../../../lib/formatting';
import { Installation } from '../../../redux/api/iotCloud/types';

type CellProps = {
  data: string | number | null | undefined;
  linkUrl?: string | null;
  header?: boolean;
};

const Cell = ({ data, linkUrl, header }: CellProps) => {
  const dataComponent = data ? (
    linkUrl ? (
      <Link
        href={linkUrl}
        onClick={ev => {
          ev.stopPropagation();
        }}
        underline="none"
        target="_blank"
      >
        {data}
      </Link>
    ) : (
      data
    )
  ) : (
    '-'
  );

  return (
    <TableCell>
      {header ? (
        <Typography variant="h6">{dataComponent}</Typography>
      ) : (
        dataComponent
      )}
    </TableCell>
  );
};

type InstallationsTableProps = {
  installations?: Installation[];
};

const InstallationsTable = ({ installations }: InstallationsTableProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'servicePortal.installationPage',
  });
  const navigate = useNavigate();

  const handleRowPress = (event: MouseEvent<HTMLTableRowElement>) => {
    const { projectId, installationId } = event.currentTarget.dataset;
    navigate(`/servicePortal/installation/${projectId}/${installationId}`);
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <Cell header data="ID" />
            <Cell header data={t('projectId')} />
            <Cell header data={t('location')} />
            <Cell header data={t('gatewayType')} />
            <Cell header data={t('kgGatewaySerialNumber')} />
            <Cell header data={t('created')} />
            <Cell header data={t('updated')} />
          </TableRow>
        </TableHead>
        <TableBody>
          {installations &&
            installations.map(
              ({
                id,
                projectId,
                lat,
                lng,
                gatewayType,
                kgGatewaySerialNumber,
                createdAt,
                updatedAt,
              }) => {
                return (
                  <TableRow
                    key={id}
                    hover
                    data-project-id={projectId}
                    data-installation-id={id}
                    onClick={handleRowPress}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Cell data={id} />
                    <Cell data={projectId} />
                    <Cell
                      data={`${lat}, ${lng}`}
                      linkUrl={config.servicePage.googleMapsLinkByCoordinates(
                        lat,
                        lng,
                      )}
                    />
                    <Cell data={gatewayType} />
                    <Cell data={kgGatewaySerialNumber} />
                    <Cell data={formatTimestamp(createdAt)} />
                    <Cell data={formatTimestamp(updatedAt)} />
                  </TableRow>
                );
              },
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InstallationsTable;
