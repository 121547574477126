import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  SxProps,
  Theme,
} from '@mui/material';
import { ReactElement } from 'react';

export type ButtonWithIconColorVariants = 'primary' | 'secondary' | 'tertiary';

const buttonWithIconColorVariantsStyleMap: {
  [key in ButtonWithIconColorVariants]: SxProps<Theme>;
} = {
  primary: {
    backgroundColor: 'primary.main',
    ':not([disabled]):hover': {
      backgroundColor: 'primary.dark',
    },
  },
  secondary: {
    backgroundColor: 'secondary.main',
    ':not([disabled]):hover': {
      backgroundColor: 'secondary.dark',
    },
  },
  tertiary: {
    backgroundColor: 'success.main',
    ':not([disabled]):hover': {
      backgroundColor: 'success.dark',
    },
    '&[disabled]:hover': {
      backgroundColor: 'success.main',
    },
  },
};

interface ButtonWithIconProps extends ButtonProps {
  icon: ReactElement;
  iconPosition?: 'left' | 'right';
  colorVariant?: ButtonWithIconColorVariants;
  isLoading?: boolean;
}

const ButtonWithIcon = ({
  icon,
  iconPosition = 'right',
  colorVariant = 'primary',
  isLoading = false,
  ...props
}: ButtonWithIconProps) => {
  const { children, sx, ...buttonProps } = props;
  const buttonStyles = {
    ...buttonWithIconColorVariantsStyleMap[colorVariant],
    ...sx,
  };
  return (
    <Button
      {...buttonProps}
      sx={{
        px: 2,
        flexDirection: iconPosition === 'right' ? 'row' : 'row-reverse',
        color: 'common.white',
        gap: 1.25,
        ':disabled': {
          color: 'common.white',
          filter: 'brightness(50%)',
        },
        ...buttonStyles,
      }}
    >
      {children}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {isLoading ? <CircularProgress size={25} sx={{ flex: 1 }} /> : icon}
      </Box>
    </Button>
  );
};

export default ButtonWithIcon;
