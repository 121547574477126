import { Dialog } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloudIcon } from '../../../../../assets/icons/cloud.svg';
import { useSnackbar } from '../../../../../context/SnackbarContext';
import { usePostConnectGatewayMutation } from '../../../../../redux/api/montageApi';
import { GatewayConnectionStatus } from '../../../../../redux/api/montageApi/types';
import { SnackbarAlertType } from '../../../../common/CustomSnackbar';
import CustomDialogTitle from '../../../../common/dialog/CustomDialogTitle';
import GatewayConnectionDialogFirstStageContent, {
  GatewayConnectionDialogInputs,
} from './GatewayConnectionDialogFirstStageContent';
import GatewayConnectionDialogSecondStageContent from './GatewayConnectionDialogSecondStageContent';

interface GatewayConnectionDialogProps {
  customerNumber: string;
  serialNumber: string;
  isOpen: boolean;
  projectId: string;
  gatewaySecret: string;
  onClose: () => void;
}

const connectionStatusSnackbarDataMap: {
  [key in GatewayConnectionStatus]: {
    messageKey: string;
    type: SnackbarAlertType;
  };
} = {
  success: {
    messageKey: 'servicePage.gatewayConnectionDialog.successMessage',
    type: 'success',
  },
  already_connected: {
    messageKey:
      'servicePage.gatewayConnectionDialog.alreadyConnectedErrorMessage',
    type: 'error',
  },
  failed: {
    messageKey: 'servicePage.gatewayConnectionDialog.generalErrorMessage',
    type: 'error',
  },
  user_account_not_found: {
    messageKey: 'servicePage.gatewayConnectionDialog.userNotFoundErrorMessage',
    type: 'error',
  },
  secret_not_found: {
    messageKey:
      'servicePage.gatewayConnectionDialog.secretNotFoundErrorMessage',
    type: 'error',
  },
};

const GatewayConnectionDialog = ({
  customerNumber,
  serialNumber,
  projectId,
  isOpen,
  gatewaySecret,
  onClose,
}: GatewayConnectionDialogProps) => {
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation('translation');

  const [dialogStage, setDialogStage] = useState<'first' | 'second'>('first');
  const [inputs, setInputs] = useState<GatewayConnectionDialogInputs>({
    serialNumber,
    gatewaySecret,
  });

  const [connectGateway] = usePostConnectGatewayMutation();

  useEffect(
    function resetStageOnClose() {
      setTimeout(() => {
        setDialogStage('first');
        setInputs({ serialNumber, gatewaySecret });
      }, 300);
    },
    [isOpen, serialNumber, gatewaySecret],
  );

  const onInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: keyof GatewayConnectionDialogInputs,
  ) => {
    setInputs(inputs => ({ ...inputs, [type]: e.target.value }));
  };

  const showSecondStage = () => {
    setDialogStage('second');
  };

  const handleConnectGateway = () => {
    connectGateway({ projectId, ...inputs })
      .unwrap()
      .then(data => {
        const { messageKey, type } =
          connectionStatusSnackbarDataMap[data.gatewayConnectionStatus];
        showSnackbar(t(messageKey), type);
      })
      .catch(error => {
        showSnackbar(
          t('servicePage.gatewayConnectionDialog.generalErrorMessage'),
          'error',
        );
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      sx={{ maxWidth: '670px', mx: 'auto' }}
    >
      <CustomDialogTitle icon={<CloudIcon fill="black" />} onClose={onClose}>
        {t('servicePage.gatewayConnectionDialog.title')}
      </CustomDialogTitle>
      {dialogStage === 'first' ? (
        <GatewayConnectionDialogFirstStageContent
          customerNumber={customerNumber}
          onClose={onClose}
          onConfirm={showSecondStage}
          inputs={inputs}
          onInputChange={onInputChange}
        />
      ) : (
        <GatewayConnectionDialogSecondStageContent
          customerNumber={customerNumber}
          onClose={onClose}
          onConfirm={handleConnectGateway}
          serialNumber={inputs.serialNumber}
          gatewaySecret={inputs.gatewaySecret}
        />
      )}
    </Dialog>
  );
};

export default GatewayConnectionDialog;
